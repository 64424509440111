import cn from 'classnames'

import { configuration } from 'mmfintech-backend-api'
import { getCurrencyImageUrl } from 'mmfintech-commons'

import './currencyIcon.scss'

import PlaceholderIcon from '@images/currency-placeholder.png'

interface CurrencyIconProps {
  currency?: string
  size?: string
  circle?: boolean
  className?: string
}

export function CurrencyIcon({ currency, size = '3rem', circle = false, className = '' }: CurrencyIconProps) {
  const currencyIcon = findCurrencyIconSrc(currency)

  return (
    <img
      data-test={`currency-icon-${currencyIcon || PlaceholderIcon}`}
      style={{ width: size, height: size }}
      src={currency ? currencyIcon : PlaceholderIcon}
      alt={currency + '-icon'}
      className={cn('currency-icon-wrapper', className, {
        circle
      })}></img>
  )
}

export const findCurrencyIconSrc = (currency: string) =>
  `${configuration.getBaseUrl()}${getCurrencyImageUrl(currency, 'svg')}`
