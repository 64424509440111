import { useHistory } from 'react-router-dom'

import CoreButton from '@components/CoreButton'
import CorePreviewLine from '@components/CorePreviewLine'
import CoreStatusView from '@components/CoreStatusView'
import ArrowUpRight from '@images/icons/arrow-up-right-from-square-solid.svg?react'
import BackArrowIcon from '@images/icons/transaction-details-back-arrow-left-icon.svg?react'
import TransactionsExportDocumentIcon from '@images/icons/transaction-export-icon.svg?react'
import cn from 'classnames'
import fileDownload from 'js-file-download'

import {
  endpoints,
  formatTransactionDescription,
  getPaymentOptionName,
  getTransactionStatusName,
  useCurrencies,
  useFileDownloader,
  useTransactionDetailsQry
} from 'mmfintech-backend-api'
import { formatDateTime, formatFloat, formatMoney, getCurrencySymbol, tr } from 'mmfintech-commons'
import { PaymentMethodEnum, TransactionDirectionEnum, TransactionStatusEnum } from 'mmfintech-commons-types'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import { cutCryptoAddress } from '../../utils'
import './styled/transactionsDetails.scss'

function TransactionDetails({ isModal = false, transactionId = null }) {
  const history = useHistory()
  const downloader = useFileDownloader()

  const { getCurrencyPrecision } = useCurrencies()
  const { transaction, transactionError, transactionFetching } = useTransactionDetailsQry(transactionId)

  const {
    id,
    reqTime,
    status,
    direction,
    amount: transactionAmount,
    currency: transactionCurrency,
    reference,
    accountName,
    transactionCategory,
    paymentMethod,
    cryptoDetails,
    emoneyTransferDetails,
    emoneyInternalTransferDetails,
    bankwireDetails,
    feeDetails,
    luqapayDetails,
    celcoinDetails,
    externalWalletDetails,
    cardPaymentDetails,
    trnTypeLocalizationKey
  } = transaction || {}
  const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } = cardPaymentDetails || {}

  const { amount: feeAmount, currency: feeCurrency } = feeDetails || {}
  const { name: externalWalletName, identifier: externalWalletIdentifier } = externalWalletDetails || {}
  const { counterpartyAddress, blockchainExplorerUrl, txHash } = cryptoDetails || {}

  const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
  const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

  const getCounterpartyName = () =>
    bankwireDetails?.counterpartyName ??
    emoneyTransferDetails?.counterpartyName ??
    emoneyInternalTransferDetails?.accountName ??
    luqapayDetails?.counterpartyName ??
    celcoinDetails?.counterPartyName ??
    externalWalletDetails?.name

  const handleTransactionReceiptClick = (transactionId: number) => {
    if (transactionId > 0) {
      void downloader.download({
        url: endpoints.transactions.getTransferReceipt(transactionId),
        method: 'GET',
        onSuccess: (data: any, filename?: string) => {
          fileDownload(data, filename || `transaction-receipt-${transactionId}.pdf`, 'application/pdf')
        }
      })
    }
  }

  return (
    <div className={cn('transaction-details-container', { 'is-modal': isModal })}>
      {!isModal && (
        <div className='transaction-details-header-wrapper'>
          <CoreButton
            title={tr('FRONTEND.TRANSACTIONS.DETAILS.BUTTON.BACK', 'Back')}
            data-test='transaction-details-back-button'
            LeftIcon={<BackArrowIcon />}
            style={{ marginRight: '2rem' }}
            className='transaction-details-header-back-button'
            variation='secondary'
            onClick={() => history.goBack()}
          />

          {paymentMethod === PaymentMethodEnum.BANKWIRE && (
            <CoreButton
              data-test='transaction-details-PDF-download-button'
              title={tr('FRONTEND.TRANSACTIONS.DETAILS.BUTTON.DOWNLOAD', 'Download as PDF')}
              LeftIcon={<TransactionsExportDocumentIcon />}
              onClick={() => handleTransactionReceiptClick(id)}
              variation='secondary'
              className='transaction-details-header-PDF-button'
            />
          )}
        </div>
      )}

      {!isModal && (
        <h3 data-test='transaction-details-title'>
          {tr('FRONTEND.TRANSACTIONS.DETAILS.TITLE', 'Transaction details')}
        </h3>
      )}

      {transactionFetching ? <Preloader /> : transactionError ? <ErrorDisplay error={transactionError} /> : null}
      <div className='transaction-details-wrapper'>
        {amount && (
          <div className='transaction-details-content-header-wrapper'>
            <span data-test='transaction-details-amount-label' className='transaction-details-header'>
              {tr('FRONTEND.TRANSACTIONS.DETAILS.AMOUNT', 'Amount')}
            </span>
            <span data-test='transaction-details-PDF-amount-value' className='value'>
              <span data-test='amount'>
                {getCurrencySymbol(currency)}{' '}
                {formatFloat(
                  amount * (direction === TransactionDirectionEnum.WITHDRAW ? -1 : 1),
                  getCurrencyPrecision(currency)
                )}
              </span>
              <div className='status'>
                <CoreStatusView
                  data-test='transaction-details-status'
                  status={getTransactionStatusName(status) as TransactionStatusEnum}
                />
              </div>
            </span>
          </div>
        )}

        {!transactionFetching && (
          <div className='transaction-details-content-wrapper'>
            <div className='transaction-details-inner'>
              <CorePreviewLine
                className={'transaction-details-single-content'}
                transactionCategory={transactionCategory}
                iconType={'category-transaction'}
                title={tr('FRONTEND.TRANSACTIONS.DETAILS.TYPE', 'Type')}
                text={tr(trnTypeLocalizationKey, transactionCategory?.replace(/_/g, ' '))}
              />
              <CorePreviewLine
                className={'transaction-details-single-content'}
                iconType={'date'}
                title={tr('FRONTEND.TRANSACTIONS.DETAILS.DATE', 'Date')}
                text={formatDateTime(reqTime)}
              />
              <CorePreviewLine
                className={'transaction-details-single-content'}
                iconType={'recipient'}
                title={
                  direction === TransactionDirectionEnum.WITHDRAW
                    ? tr('FRONTEND.TRANSACTIONS.DETAILS.TO', 'To')
                    : tr('FRONTEND.TRANSACTIONS.DETAILS.FROM', 'From')
                }
                text={getCounterpartyName()}
              />
              <CorePreviewLine
                className={'transaction-details-single-content'}
                iconType={'account'}
                title={tr('FRONTEND.TRANSACTIONS.DETAILS.ACCOUNT', 'Account')}
                text={accountName}
              />
              {!!feeDetails && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'percentage'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.FEE', 'Fee')}
                  text={formatMoney(feeAmount, feeCurrency)}
                />
              )}
              {id && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'transaction-id'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.TRANSACTION_ID', 'Transaction ID')}
                  text={id.toString()}
                />
              )}
              {paymentMethod && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'method'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.METHOD', 'Method')}
                  text={getPaymentOptionName(paymentMethod)}
                />
              )}
              <CorePreviewLine
                className={'transaction-details-single-content'}
                iconType={'reference'}
                title={tr('FRONTEND.TRANSACTIONS.DETAILS.REFERENCE', 'Reference')}
                text={reference}
              />
              {counterpartyAddress && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'transaction-id'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.CRYPTO_ADDRESS', 'Address')}
                  text={cutCryptoAddress(counterpartyAddress)}
                  value={counterpartyAddress}
                  isCopyVisible={true}
                />
              )}

              {externalWalletName && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.WALLET_NAME', 'External wallet name')}
                  text={externalWalletName}
                />
              )}

              {externalWalletIdentifier && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.WALLET_ID', 'External wallet ID')}
                  text={externalWalletIdentifier}
                />
              )}

              {txHash && (
                <CorePreviewLine
                  className={'transaction-details-single-content tx-hash'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.TRN_HASH', 'Transaction hash')}
                  text={<HashLink hash={cutCryptoAddress(txHash)} link={blockchainExplorerUrl} />}
                  value={txHash}
                  isCopyVisible={true}
                />
              )}
            </div>

            <CorePreviewLine
              className={'transaction-details-description-content'}
              iconType={'description'}
              title={tr('FRONTEND.TRANSACTIONS.DETAILS.DESCRIPTION', 'Description')}
              text={formatTransactionDescription(transaction)}
            />

            {isModal && paymentMethod === PaymentMethodEnum.BANKWIRE && (
              <div className='transaction-details-footer-PDF-button'>
                <CoreButton
                  data-test='transaction-details-PDF-download-button'
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.BUTTON.DOWNLOAD', 'Download as PDF')}
                  LeftIcon={<TransactionsExportDocumentIcon />}
                  onClick={() => handleTransactionReceiptClick(id)}
                  variation='secondary'
                  className='transaction-details-header-PDF-button'
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default TransactionDetails

type HashLinkProps = {
  hash?: string
  link?: string
}

export const HashLink = ({ hash, link }: HashLinkProps) => {
  if (hash) {
    if (link) {
      return (
        <p>
          <a href={link} target='_blank' rel='noreferrer' className='hash-display'>
            {hash}
            <ArrowUpRight className='hash-icon' width={16} height={16} />
          </a>
        </p>
      )
    }
    return <p>{hash}</p>
  }

  return null
}
