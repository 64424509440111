// noinspection JSCheckFunctionSignatures
import React from 'react'

import DemoStyleViewer from '@components/demoStyleViewer/DemoStyleViewer'
import Accounts from '@views/account/Accounts'
import Card from '@views/card'
import Dashboard from '@views/dashboard/Dashboard'
import Transactions from '@views/transactions/Transactions'

import { paths } from 'mmfintech-backend-api'

import { adsPagePaths } from './utils'

const Login = React.lazy(() => import('@views/auth/Login'))
const Signup = React.lazy(() => import('@views/auth/Signup'))
const ForgottenPasswordEmail = React.lazy(() => import('@views/static/ForgottenPasswordEmail'))
const ResetPassword = React.lazy(() => import('@views/auth/ResetPassword'))
const SetPassword = React.lazy(() => import('@views/auth/SetPassword'))
const VerifyEmailMessage = React.lazy(() => import('@views/static/CheckEmail'))
const VerifyEmail = React.lazy(() => import('@views/auth/VerifyEmail'))
const VerificationSumSub = React.lazy(() => import('@views/onboarding/SumSubVerification'))

const Profile = React.lazy(() => import('@views/settings/Profile'))

const TransactionDetails = React.lazy(() => import('@views/transactions/TransactionDetails'))

const Deposit = React.lazy(() => import('@views/banking/Deposit'))
const DepositPreview = React.lazy(() => import('@views/banking/preview/DepositPreview'))
const Exchange = React.lazy(() => import('@views/banking/Exchange'))
const Withdraw = React.lazy(() => import('@views/banking/SendMoney'))
const TransactionStatus = React.lazy(() => import('@views/banking/common/TransactionStatus'))

const SendTransfer = React.lazy(() => import('@views/banking/InternalTransfer'))
const SendBankTransfer = React.lazy(() => import('@views/banking/BankTransfer'))

const Recipients = React.lazy(() => import('@views/recipients/Recipients'))

const Invite = React.lazy(() => import('@views/auth/Invite'))

const RegisterAdsPage = React.lazy(() => import('@views/adsPages/RegisterAdsPage'))

const Error404 = React.lazy(() => import('@views/static/Error404'))
const SuccessPane = React.lazy(() => import('@views/banking/success'))

// prettier-ignore
const routes = [
  { path: paths.root(), redirect: paths.dashboard() },

  { path: paths.banking.transferSuccess(), PrivateComponent: SuccessPane },

  { path: paths.dashboard(), PrivateComponent: Dashboard },
  { path: paths.settings(), PrivateComponent: Profile },
  { path: paths.banking.depositPreview(), exact: true, PrivateComponent: DepositPreview },
  { path: paths.banking.deposit(), PrivateComponent: Deposit },
  { path: paths.banking.exchangeFromTo(':fromCurrency', ':toCurrency'), PrivateComponent: Exchange },
  { path: paths.banking.exchange(), PrivateComponent: Exchange },

  { path: paths.banking.sendTransfer(), exact: true, PrivateComponent: SendTransfer },
  { path: paths.banking.sendBankTransfer(), exact: true, PrivateComponent: SendBankTransfer },

  { path: paths.banking.sendTo(':recipientId'), PrivateComponent: Withdraw },
  { path: paths.banking.send(), PrivateComponent: Withdraw },

  { path: paths.banking.transactions.details(':transactionId'), PrivateComponent: TransactionDetails },
  { path: paths.recipients.list(), PrivateComponent: Recipients },
  { path: paths.banking.accounts.list(), PrivateComponent: Accounts },
  { path: paths.banking.transactions.listByAccountId(':accountId'), PrivateComponent: Transactions },
  { path: paths.banking.transactions.list(), PrivateComponent: Transactions },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, PrivateComponent: VerificationSumSub },
  { path: paths.onboarding.sumSub.continue(), exact: true, PrivateComponent: VerificationSumSub },
  { path: paths.banking.finalStatus.fail(':method'), exact: true, PrivateComponent: TransactionStatus },
  { path: paths.banking.finalStatus.success(':method'), exact: true, PrivateComponent: TransactionStatus },
  { path: '/cards', PrivateComponent: Card }
]

// prettier-ignore
const nonProtectedRoutes = [

  {path: adsPagePaths.ONE_APP , Component: RegisterAdsPage},
  {path: adsPagePaths.CARD_RIGHT_AWAY , Component: RegisterAdsPage},
  { path: paths.passwordReset(), redirect: paths.login() },
  { path: paths.login(), Component: Login },
  { path: paths.signUp(), Component: Signup },
  { path: paths.invite(':link'), exact: true, Component: Invite },
  { path: paths.checkEmail(), Component: VerifyEmailMessage },

  { path: paths.changePasswordEmail(), Component: ForgottenPasswordEmail },
  { path: paths.handlePasswordReset(), Component: ResetPassword },
  { path: paths.handlePasswordResetInvited(), Component: SetPassword },
  { path: paths.handleEmailVerify(), Component: VerifyEmail },

  { path: paths.error404(), Component: Error404 },

  //styles
  {
    path: '/style-view',
    Component: DemoStyleViewer,
    params: { isSuccess: true }
  }
]

export { routes, nonProtectedRoutes }
