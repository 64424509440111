import { useContext } from 'react'

import CoreButton from '@components/CoreButton'

import { useAcceptLegalRequirementsMutation } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { LegalRequirementResponse } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { LegalRequirements } from './LegalRequirements'
import './LegalRequirementsModal.scss'

interface LegalRequirementsModal {
  requirements: LegalRequirementResponse[]
  onAccepted?: () => void
  isInModal?: boolean
}

export const LegalRequirementsModal = ({ requirements, onAccepted, isInModal }: LegalRequirementsModal) => {
  const { modalHide } = useContext(GlobalContext)

  const [acceptLegalRequirements, { error: acceptLegalRequirementsError, isLoading: acceptLegalRequirementsFetching }] =
    useAcceptLegalRequirementsMutation()

  const handleAccept = () => {
    const requirementIds = requirements.map(entry => entry.id)
    acceptLegalRequirements({ requirementIds })
      .unwrap()
      .then(() => {
        if (!isInModal) {
          modalHide()
        }
        onAccepted && onAccepted()
      })
      .catch(() => {
        return
      })
  }

  return (
    <div className='legal-requirements-modal'>
      <LegalRequirements requirements={requirements} />

      <ErrorDisplay error={acceptLegalRequirementsError} />

      <CoreButton
        title={tr('PORTAL.LEGAL.ACCEPT_BUTTON', 'Accept')}
        variation='primary'
        fullWidth
        size='normal'
        onClick={handleAccept}
        isLoading={acceptLegalRequirementsFetching}
      />
    </div>
  )
}
