import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import ChevronIcon from '@images/icons/chevron-right-red.svg?react'
import ArrowIcon from '@images/icons/red-arrow-up.svg?react'
import ExchangeIcon from '@images/icons/red-exchange.svg?react'
import PlusIcon from '@images/icons/red-plus.svg?react'
import cn from 'classnames'

import { paths, useGetAggregatedBalanceQuery, useMerchantAccounts } from 'mmfintech-backend-api'
import { formatMoney, isValidArray, tr } from 'mmfintech-commons'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import CoreButton from '../CoreButton'
import CoreSelect from '../CoreSelect'
import './cardsCarousel.scss'

import SelectAccountImage from '@images/account-select.png'

const CardsCarousel = () => {
  const {
    data: aggregatedBalance,
    isFetching: aggregatedBalanceFetching,
    error: aggregatedBalanceError
  } = useGetAggregatedBalanceQuery({
    dateFrom: '',
    dateTo: ''
  })

  const [animate, setAnimate] = useState<boolean>(false)
  const [currentBalance, setCurrentBalance] = useState<string>('')
  const [newBalance, setNewBalance] = useState<string>(null)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const { accounts: paymentAccounts } = useMerchantAccounts()

  useEffect(() => {
    if (typeof newBalance == 'string') {
      setAnimate(currentState => !currentState)
      setTimeout(() => {
        setAnimate(currentState => !currentState)
        setCurrentBalance(newBalance)
      }, 650)
    }
  }, [newBalance])

  return (
    <div className='cards-carousel-wrapper'>
      <div className='cards-carousel-top-bar'>
        <h2 data-test='cards-carousel-overview-title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</h2>
        <CoreSelect
          data-test='cards-carousel-overview-selection'
          LeftIcon={<img src={SelectAccountImage} width={30} alt='' />}
          type='account'
          label={tr('FRONTEND.DASHBOARD.ACCOUNT_CAROUSEL.ALL_ACCOUNT', 'All Accounts')}
          value={selectedAccount}
          allAccounts={isValidArray(paymentAccounts)}
          options={paymentAccounts}
          hideCaption
          onChange={(_, account) => {
            if (account === null) {
              setSelectedAccount(null)
              return setNewBalance(displayedBalance => {
                const newBalanceValue = isValidArray(aggregatedBalance)
                  ? formatMoney(
                      aggregatedBalance[aggregatedBalance.length - 1].amount,
                      aggregatedBalance[aggregatedBalance.length - 1].currencyCode
                    )
                  : '0.00 EUR'
                // check if the new value is the same as the old one, so we can initiate the animation
                // if the values match useEffect is not called and the animation is not invoked
                return newBalanceValue + (displayedBalance == newBalanceValue ? ' ' : '')
              })
            }
            setNewBalance(displayedBalance => {
              const newBalanceValue = formatMoney(account?.balance, account?.currencyCode)
              return newBalanceValue + (displayedBalance == newBalanceValue ? ' ' : '')
            })
            setSelectedAccount(account)
          }}
        />
        <div className='cards-carousel-top-bar-right-section'>
          <Link to={paths.banking.accounts.list()} data-test='accounts' className='cards-carousel-top-bar-link'>
            <span data-test='cards-carousel-overview-view-all-label'>
              {tr('FRONTEND.DASHBOARD.VIEW_ALL', 'View all')}
            </span>
            <ChevronIcon />
          </Link>
        </div>
      </div>
      <div className='cards-carousel-container'>
        <CarouselCard
          data-test='cards-carousel-active-content'
          className={cn('carousel-card-selected', { animate: animate })}
          key={'selected'}
          aggregatedBalanceFetching={aggregatedBalanceFetching}
          isTotal={!selectedAccount}
          selectedAccount={selectedAccount}
          aggregatedBalanceError={aggregatedBalanceError}
          balance={
            currentBalance ||
            (isValidArray(aggregatedBalance) &&
              formatMoney(
                aggregatedBalance[aggregatedBalance.length - 1].amount,
                aggregatedBalance[aggregatedBalance.length - 1].currencyCode
              ))
          }
        />
        <CarouselCard
          className={cn('carousel-card-new', { animate: animate })}
          key={'new'}
          balance={newBalance}
          isTotal={!selectedAccount}
        />
        <CarouselCard className={cn('carousel-card-placeholder', { animate: animate })} key={'placeholder'} empty />
        <CarouselCard className={cn('carousel-card-hidden', { animate: animate })} key={'hidden'} empty />
      </div>
    </div>
  )
}

export default CardsCarousel

const CarouselCard = ({
  className,
  balance,
  aggregatedBalanceFetching,
  empty,
  isTotal,
  aggregatedBalanceError,
  selectedAccount
}: {
  className?: string
  balance?: string
  aggregatedBalanceFetching?: boolean
  empty?: boolean
  isTotal?: boolean
  aggregatedBalanceError?: any
  selectedAccount?: any
}) => {
  const history = useHistory()

  return (
    <div className={cn(className, 'carousel-card-wrapper')}>
      {!empty && (
        <>
          {aggregatedBalanceFetching ? (
            <Spinner />
          ) : (
            <div className='carousel-card-balance-section'>
              <p data-test='carousel-card-balance-title' className='carousel-card-caption'>
                {isTotal
                  ? tr('FRONTEND.DASHBOARD.ACCOUNTS.TOTAL_BALANCE', 'Total balance')
                  : tr('FRONTEND.DASHBOARD.ACCOUNTS.BALANCE', 'Balance')}
              </p>
              <h2 data-test='carousel-card-balance-value' className='carousel-card-balance' translate='no'>
                <ErrorDisplay error={aggregatedBalanceError} />
                {balance}
              </h2>
            </div>
          )}
          <div className='carousel-card-actions'>
            <CoreButton
              data-test='carousel-card-deposit-button'
              LeftIcon={<PlusIcon />}
              title={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
              variation='tertiary'
              size='normal'
              fullWidth
              onClick={() =>
                history.push({
                  pathname: paths.banking.deposit(),
                  state: { preselectedAccount: selectedAccount?.accountId }
                })
              }
            />
            <CoreButton
              data-test='carousel-card-send-button'
              LeftIcon={<ArrowIcon />}
              title={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
              variation='tertiary'
              size='normal'
              fullWidth
              onClick={() => history.push(paths.banking.send())}
            />
            <CoreButton
              LeftIcon={<ExchangeIcon />}
              data-test='carousel-card-exchange-button'
              title={tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
              variation='tertiary'
              size='normal'
              fullWidth
              onClick={() => history.push(paths.banking.exchange())}
            />
          </div>
        </>
      )}
    </div>
  )
}
