import { FC, SVGProps, isValidElement } from 'react'
import toast from 'react-hot-toast'

import ClipboardIcon from '@images/icons/clipboard.svg?react'
import AccountIcon from '@images/icons/previewIcons/account-icon.svg?react'
import DateIcon from '@images/icons/previewIcons/date-icon.svg?react'
import FeeIcon from '@images/icons/previewIcons/fee-icon.svg?react'
import MethodIcon from '@images/icons/previewIcons/method-icon.svg?react'
import RecipientIcon from '@images/icons/previewIcons/recipient-icon.svg?react'
import ReferenceIcon from '@images/icons/previewIcons/reference-icon.svg?react'
import DescriptionIcon from '@images/icons/previewIcons/transaction-details-description-icon.svg?react'
import PercentIcon from '@images/icons/previewIcons/transaction-details-percent-icon.svg?react'
import TrnIdIcon from '@images/icons/previewIcons/trn-id-icon.svg?react'
import { CoreTransactionIcon } from '@views/transactions/elements/TransactionTypeWithIcon'
import cn from 'classnames'

import { copyTextToClipboard, isValidArray, isValidString, tr } from 'mmfintech-commons'

import { CardIssuerIcon } from '../../utils/helpers'
import './corePreviewLine.scss'

type iconType =
  | 'account'
  | 'date'
  | 'fee'
  | 'method'
  | 'recipient'
  | 'reference'
  | 'transaction-id'
  | 'category-transaction'
  | 'percentage'
  | 'description'

interface ICorePreviewLineProps {
  iconType: iconType
  title: string
  text: string | string[] | JSX.Element
  transactionCategory?: string
  className?: string
  value?: string
  isCopyVisible?: boolean
}

const CorePreviewLine: FC<ICorePreviewLineProps> = ({
  iconType,
  text,
  title,
  transactionCategory,
  className,
  value,
  isCopyVisible
}) => {
  return text ? (
    <div className={cn('preview-line-wrapper', className)}>
      <LineIcon data-test={`preview-line-icon-${iconType}`} type={iconType} transactionCategory={transactionCategory} />
      <div>
        <span data-test={`preview-line-title-${title}`}>{title}</span>
        <div
          data-test={`preview-line-text-${title}`}
          className={cn('preview-line-content-moving', { 'preview-line-content': iconType === 'method' })}
          onClick={() => isValidString(value) && copyTextToClipboard(value)}>
          {isValidElement(text) && text}
          {isValidString(text) ? (
            <p>{text}</p>
          ) : isValidArray(text) ? (
            text.map((line: string, index: number) => {
              if (index == 0 && line && line.length > 10) {
                const CardIcon = CardIssuerIcon(Number(line))
                return (
                  <div key={index} className='preview-line-card-number'>
                    <CardIcon />
                    <p>{'*'.repeat(12) + line.slice(-4)}</p>
                  </div>
                )
              }
              return (
                <p key={index} className='preview-line-card-details'>
                  {line}
                </p>
              )
            })
          ) : (
            ''
          )}
          {isCopyVisible && value && (
            <div className='copy'>
              <ClipboardIcon
                onClick={() =>
                  copyTextToClipboard(
                    value,
                    toast.success(tr('FRONTEND.BANKING.COMMON.COPY_SUCCESS', 'Value copied to clipboard'), {
                      position: 'top-right'
                    })
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null
}

export default CorePreviewLine

interface ILineIconProps extends SVGProps<SVGSVGElement> {
  type: iconType
  transactionCategory?: string
}

const LineIcon: FC<ILineIconProps> = ({ type, transactionCategory, ...rest }) => {
  let IconComponent
  switch (type) {
    case 'account':
      IconComponent = AccountIcon
      break
    case 'date':
      IconComponent = DateIcon
      break

    case 'fee':
      IconComponent = FeeIcon
      break

    case 'method':
      IconComponent = MethodIcon
      break

    case 'recipient':
      IconComponent = RecipientIcon
      break

    case 'reference':
      IconComponent = ReferenceIcon
      break

    case 'transaction-id':
      IconComponent = TrnIdIcon
      break
    case 'percentage':
      IconComponent = PercentIcon
      break
    case 'category-transaction':
      IconComponent = CoreTransactionIcon({ category: transactionCategory })
      break
    case 'description':
      IconComponent = DescriptionIcon
      break
    default:
      IconComponent = null
  }
  return IconComponent ? <IconComponent {...rest} /> : null
}
