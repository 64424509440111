import { useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import CardsCarousel from '@components/CardsCarousel'
import { GoogleAuthenticatorInfoModal } from '@components/GoogleAuthenticatorInfoModal'
import { LegalRequirementsModal } from '@components/LegalRequirements'
import CookieConsent from '@views/cookieConsent/CookieConsent'

import {
  selectInitialEmailVerification,
  useAppSelector,
  useGetMerchantQuery,
  useGetPendingLegalRequirementsQuery,
  useGetProfileQuery
} from 'mmfintech-backend-api'
import { GlobalContext, fixDateOnly, isValidArray, monthBackDate, tr } from 'mmfintech-commons'
import { MerchantAccountTypeEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

import './dashboard.scss'
import CardsBanner from './elements/CardsBanner'
import DashboardRecentActivity from './elements/DashboardRecentActivity'
import DownloadAppComponent from './elements/DownloadAppComponent'
import Guideline from './elements/Guideline'
import TransactionsSection from './elements/TransactionsSection'
import { WelcomeModal } from './elements/WelcomeModal'

function Dashboard() {
  const { modalShow } = useContext(GlobalContext)
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)

  const { data: merchant, isLoading: merchantLoading } = useGetMerchantQuery()
  const { accountType, capabilities, merchantId } = merchant || {}

  const [cookies] = useCookies(['authenticator'])
  const { data: profile, isLoading: profileLoading } = useGetProfileQuery(null)
  const { twoFactor } = profile || {}

  const {
    data: legalRequirements,
    isFetching: legalRequirementsFetching,
    isLoading: legalRequirementsLoading
  } = useGetPendingLegalRequirementsQuery(null)

  useEffect(() => {
    if (legalRequirementsFetching || legalRequirementsLoading || profileLoading || merchantLoading) return null
    if (initialEmailVerification) {
      modalShow({
        header: (
          <span style={{ paddingRight: '5.4rem' }}>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Jeton!')}</span>
        ),
        options: {
          size: 'medium',
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })
    } else if (!legalRequirementsFetching && isValidArray(legalRequirements)) {
      modalShow({
        options: {
          size: 'medium',
          closeOnEscape: false,
          closeOnClickOutside: false
        },
        content: <LegalRequirementsModal requirements={legalRequirements} />
      })
    } else if (
      twoFactor &&
      merchantId &&
      !cookies?.authenticator?.includes(merchantId) &&
      twoFactor !== TwoFactorTypeEnum.TOTP
    ) {
      modalShow({
        options: {
          size: 'medium',
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <GoogleAuthenticatorInfoModal />
      })
    }
    // eslint-disable-next-line
  }, [initialEmailVerification, merchantId, twoFactor, profileLoading, merchantLoading, legalRequirements])

  const transactionFilter = {
    from: fixDateOnly(monthBackDate()),
    to: fixDateOnly(new Date()),
    page: 0,
    size: 5,
    sort: 'reqTime-desc,id-desc'
  }

  return (
    <div className='dashboard-container'>
      <div className='dashboard-two-selection-container'>
        <div className='dashboard-right-section-container'>
          <div className='dashboard-wrapper'>
            <div data-test='dashboard-right-side' className='right-side'>
              {accountType !== MerchantAccountTypeEnum.PROSPECT && !!capabilities?.enableCardIssuing ? (
                <CardsBanner />
              ) : (
                <Guideline />
              )}
              <DashboardRecentActivity />
              <DownloadAppComponent />
              <CookieConsent />
            </div>
          </div>
        </div>
        <div className='dashboard-main-section-container'>
          <CardsCarousel />
          <div className='dashboard-wrapper'>
            <div data-test='dashboard-left-side' className='left-side'>
              <TransactionsSection filter={transactionFilter} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
