import { useEffect, useRef, useState } from 'react'

import { rollingBankerSettings } from '@constants'

import './RollingBanner.scss'

export const RollingBanner = () => {
  const containerRef = useRef()
  const [content, setContent] = useState(rollingBankerSettings.content)

  useEffect(() => {
    if (containerRef?.current) {
      const targetNode = containerRef.current

      const handleResize = resize => {
        //@ts-ignore
        if (targetNode?.firstChild?.clientWidth < resize[0].target.clientWidth) {
          //@ts-ignore
          const difference = Math.floor(resize[0].target.clientWidth / containerRef?.current?.firstChild?.clientWidth)
          const newContent = content + ' '.repeat(10)

          setContent(newContent.repeat(difference))
        }
      }

      const observer = new ResizeObserver(handleResize)
      observer.observe(targetNode)

      return () => {
        observer.disconnect()
      }
    } //@ts-ignore
  }, [containerRef?.current?.clientWidth])

  return (
    <div ref={containerRef} className='rolling-banner-wrapper'>
      <p className='first-content'>{content}</p>
      <p className='second-content'>{content}</p>
    </div>
  )
}
