import { useContext } from 'react'

import CardOrderModal from '@components/CardOrderModal'
import CoreButton from '@components/CoreButton'

// import { useGetAllCardsQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import './styled/cardsBanner.scss'

import JetonBankCards from '@images/bank-cards.png'

const CardsBanner = () => {
  const { modalShow, modalHide } = useContext(GlobalContext)
  // const { data: activeCards, isLoading: activeCardsLoading, error: activeCardsError } = useGetAllCardsQuery(null)

  const handleOrderClick = () => {
    modalShow({
      header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
      content: <CardOrderModal onClose={modalHide} />,
      options: {
        closeOnClickOutside: false
      }
    })
  }

  // if ((isValidArray(activeCards) && activeCards.length >= 2) || activeCardsError) return <></>

  return (
    <div className='cards-banner-container'>
      <div className='cards-banner-top-section'>
        <div className='cards-banner-title'>{tr('FRONTEND.DASHBOARD.CARDS_BANNER.TITLE', 'Jeton Cards')}</div>
        <CoreButton
          title={tr('FRONTEND.DASHBOARD.CARDS_BANNER.ORDER_BUTTON', 'Request a card')}
          size='normal'
          variation='tertiary'
          onClick={() => handleOrderClick()}
        />
      </div>

      <div className='cards-banner-bank-cards'>
        <img src={JetonBankCards} alt='bank-cards' />
      </div>

      <div className='cards-banner-text'>
        {tr(
          'FRONTEND.DASHBOARD.CARDS_BANNER.SUBTEXT',
          'Use your Jeton physical or virtual debit card for purchases at any merchant or terminal that accepts Mastercard.'
        )}
      </div>
    </div>
  )
}

export default CardsBanner
