import { useEffect, useState } from 'react'

import FlagIcon from '@components/FlagIcon'
import ArrowDownIcon from '@images/icons/chevron-down.svg?react'
import settings from '@settings'
import cn from 'classnames'
import i18n from 'i18next'

import {
  isOwnerOrAdministrator,
  useAppSelector,
  useAuth,
  useGetUserLanguageQuery,
  useSetUserLanguageMutation
} from 'mmfintech-backend-api'
import { getLanguageName, initializeLanguage, languages, useSearchParams } from 'mmfintech-commons'
import { LoginStatusEnum } from 'mmfintech-commons-types'

import './languageMenu.scss'

interface LanguageDropdownProps {
  onClick: () => void
  dropDownOpened?: boolean
}
const LanguageSettings = ({ onClick, dropDownOpened = false }: LanguageDropdownProps) => {
  const userStatus = useAuth()
  const { customerRole } = useAppSelector(state => state.auth)
  const [internalSelection, setInternalSelection] = useState<string>(i18n.language)
  const params = useSearchParams()

  const [setUserLanguage] = useSetUserLanguageMutation()
  const { data: selectedLanguage } = useGetUserLanguageQuery(undefined, {
    skip: userStatus !== LoginStatusEnum.LOGGED_IN
  })

  const [filteredLanguages, setFilteredLanguages] = useState([])

  const doSelectLanguage = (languageCode: string): void => {
    if (languageCode !== selectedLanguage) {
      if (userStatus === LoginStatusEnum.LOGGED_IN && isOwnerOrAdministrator(customerRole)) {
        setUserLanguage({ language: languageCode })
      }
    }
    initializeLanguage(languageCode)
  }

  useEffect(() => {
    const filtered = settings.languages.map(code => languages.find(v => v.code === code)).filter(v => v != null)
    setFilteredLanguages(filtered)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const langParam = params?.get('lang')
    if (langParam && settings.languages.includes(langParam)) {
      initializeLanguage(langParam)
      setInternalSelection(langParam)
    }
  }, [params])

  if (!selectedLanguage && !internalSelection) {
    return null
  }

  return (
    <div className={cn('language-dropdown-wrapper', { opened: dropDownOpened })} onClick={() => onClick && onClick()}>
      <FlagIcon
        countryCode={selectedLanguage || internalSelection}
        width='auto'
        height='2rem'
        className='language-icon'
      />
      {dropDownOpened && <span className='selected'>{getLanguageName(selectedLanguage || internalSelection)}</span>}

      <ArrowDownIcon className={cn({ flipped: dropDownOpened })} />

      {dropDownOpened && (
        <div className='language-dropdown-menu-container'>
          <ul data-test='language-menu-list'>
            {filteredLanguages?.map(v => {
              if (selectedLanguage) {
                if (v.code === selectedLanguage) return null
              } else {
                if (v.code === internalSelection) return null
              }

              return (
                <li
                  key={v.code}
                  onClick={() => {
                    doSelectLanguage(v.code)
                    setInternalSelection(v.code)
                  }}
                  data-test='language-menu-item'
                  className='language-menu-item'>
                  <FlagIcon countryCode={v.code} width='auto' height='2rem' className='language-icon' /> {v.name}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default LanguageSettings
