import { useContext } from 'react'

import CoreStatusView from '@components/CoreStatusView'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import PreviewIcon from '@images/icons/preview-icon.svg?react'
import cn from 'classnames'

import { formatTransactionDescription, getTransactionStatusName, useCurrencies } from 'mmfintech-backend-api'
import { GlobalContext, cutString, fetchLastChunk, formatDateTime, formatMoney, tr } from 'mmfintech-commons'
import { TransactionStatusEnum, TransactionViewPortal } from 'mmfintech-commons-types'
import { DataTable } from 'mmfintech-portal-commons'

import TransactionDetails from '../TransactionDetails'
import '../styled/transactionsTable.scss'
import TransactionTypeWithIcon from './TransactionTypeWithIcon'
import './styled/transactionTables.scss'

function TransactionsTable({ columns, transactions }) {
  const { getCurrencyPrecision } = useCurrencies()
  const isSmall = useMatchMedia({ breakpoint: 800, prefix: 'min' })
  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'min' })
  const isLarge = useMatchMedia({ breakpoint: breakpoints['2xl'], prefix: 'min' })
  const { modalShow } = useContext(GlobalContext)

  const handleDetailsClick = (transactionId: number) => {
    modalShow({
      header: tr('FRONTEND.TRANSACTIONS.DETAILS.TITLE', 'Transaction details'),
      content: <TransactionDetails isModal={true} transactionId={transactionId} />,
      options: {
        size: 'auto'
      }
    })
  }

  return (
    <div className='table-container transaction-table-container'>
      <DataTable.Table skipHover>
        <DataTable.Head>
          <DataTable.Row>
            {columns.isVisible('type') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}
              </DataTable.HeadCell>
            )}
            {isSmall && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
              </DataTable.HeadCell>
            )}
            {/* Recipient is missing , so I set the description column */}
            {isLarge && columns.isVisible('description') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('account') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('id') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'Transaction ID')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('method') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('reference') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('status') && (
              <DataTable.HeadCell className='thin no-border  table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell
              style={{
                textAlign: 'right'
              }}
              className='no-border table-header-cell'>
              {tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
            </DataTable.HeadCell>
            <DataTable.HeadCell className='buttons no-border' />
          </DataTable.Row>
        </DataTable.Head>

        <DataTable.Body>
          {transactions.map((data: TransactionViewPortal, index: number) => {
            const {
              id,
              amount: transactionAmount,
              currency: transactionCurrency,
              status,
              reqTime,
              direction,
              reference,
              paymentMethod,
              trnTypeLocalizationKey,
              transactionCategory,
              accountName,
              cardPaymentDetails
            } = data
            const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } =
              cardPaymentDetails || {}

            const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
            const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

            return (
              <DataTable.Row
                key={`data-${index}`}
                data-test='table-row'
                onClick={() => {
                  if (!isMobile) {
                    handleDetailsClick(id)
                  }
                }}>
                {columns.isVisible('type') && (
                  <DataTable.Cell
                    className='no-border table-cell'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}
                    data-test='type'>
                    {!isSmall ? (
                      <div>
                        <TransactionTypeWithIcon
                          transactionType={tr(
                            trnTypeLocalizationKey,
                            fetchLastChunk(trnTypeLocalizationKey).replace(/_/g, ' ')
                          )}
                          category={transactionCategory}
                          description={formatDateTime(reqTime)}
                        />
                      </div>
                    ) : (
                      <TransactionTypeWithIcon
                        transactionType={tr(
                          trnTypeLocalizationKey,
                          fetchLastChunk(trnTypeLocalizationKey).replace(/_/g, ' ')
                        )}
                        category={transactionCategory}
                      />
                    )}
                  </DataTable.Cell>
                )}
                {isSmall && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                    className='no-wrap no-border table-cell'
                    data-test='date'>
                    {formatDateTime(reqTime)}
                  </DataTable.Cell>
                )}

                {isLarge && columns.isVisible('description') && (
                  <DataTable.Cell
                    className='no-border table-cell table-text-limits'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                    data-test='description'>
                    {formatTransactionDescription(data)}
                  </DataTable.Cell>
                )}
                {isMobile && columns.isVisible('account') && (
                  <DataTable.Cell
                    className='no-border table-cell'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}
                    data-test='account-name'>
                    {accountName}
                  </DataTable.Cell>
                )}

                {isMobile && columns.isVisible('id') && (
                  <DataTable.Cell
                    className='no-border table-cell'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}
                    data-test='id'>
                    {id}
                  </DataTable.Cell>
                )}

                {isMobile && columns.isVisible('method') && (
                  <DataTable.Cell
                    className='no-border table-cell'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}
                    data-test='payment-method'>
                    {tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                  </DataTable.Cell>
                )}
                {isMobile && columns.isVisible('reference') && (
                  <DataTable.Cell
                    className='no-border table-cell table-text-limits'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}
                    data-test='reference'>
                    {reference ? (reference?.length > 50 ? cutString(reference, 50) + '...' : reference) : 'N/A'}
                  </DataTable.Cell>
                )}
                {isMobile && columns.isVisible('status') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                    className='status-cell no-border table-cell'
                    data-test='status'>
                    <CoreStatusView status={getTransactionStatusName(status) as TransactionStatusEnum} />
                  </DataTable.Cell>
                )}
                <DataTable.Cell
                  className='no-border'
                  style={{
                    textAlign: 'right'
                  }}
                  caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
                  data-test='amount'>
                  <div className='amount-container' translate='no'>
                    <span className={cn({ cancelled: status === 'CANCELLED' })}>
                      {formatMoney(
                        amount * (direction === 'WITHDRAW' ? -1 : 1),
                        currency,
                        getCurrencyPrecision(currency)
                      )}
                    </span>
                    {!isMobile && (
                      <CoreStatusView status={getTransactionStatusName(status) as TransactionStatusEnum} fade={true} />
                    )}
                  </div>
                </DataTable.Cell>
                {isMobile && (
                  <DataTable.Cell
                    onClick={() => handleDetailsClick(id)}
                    className='no-border transaction-details-button'
                    table-cell>
                    <PreviewIcon />
                  </DataTable.Cell>
                )}
              </DataTable.Row>
            )
          })}
        </DataTable.Body>
      </DataTable.Table>
    </div>
  )
}

export default TransactionsTable
