import { useEffect, useRef, useState } from 'react'

import cn from 'classnames'

import './coreTabs.scss'

interface ITab {
  label: string
  value: string
}

interface ICoreTabs {
  onChangeTab: (value: any) => void
  tabs: ITab[]
  preselected: string
  disabled?: boolean
}

const CoreTabs = ({ tabs, onChangeTab, preselected, disabled = false }: ICoreTabs) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(null)
  const [backgroundLeftSide, setUnderlineLeft] = useState(0)
  const [backgroundWidth, setBackgroundWidth] = useState(0)
  const selectedRef = useRef<HTMLInputElement>(null)

  const handleTabClick = e => {
    setUnderlineLeft(e.target.offsetLeft + Math.round(e.target.offsetWidth / 2))
    setBackgroundWidth(e.target.offsetWidth)
  }

  useEffect(() => {
    setSelectedTabIndex(() => {
      return tabs.indexOf(tabs.find((tab: ITab) => tab.value === preselected))
    })
  }, [preselected])

  useEffect(() => {
    const targetNode = selectedRef.current

    const callback = resizeEntries => {
      for (const resizeEntry of resizeEntries) {
        if (selectedRef.current === resizeEntry.target) {
          setBackgroundWidth(resizeEntry.target.offsetWidth)
          setUnderlineLeft(resizeEntry.target.offsetLeft + Math.round(resizeEntry.target.offsetWidth / 2))
        }
      }
    }
    const observer = new ResizeObserver(callback)
    observer.observe(targetNode)
    return () => {
      observer.disconnect()
    }
  }, [selectedRef.current])

  return (
    <div data-test='core-tabs-wrapper' className={cn('core-tabs-wrapper', { disabled: disabled })}>
      {tabs.map((tab, index) => {
        return (
          <div
            key={index}
            data-test={`tab-item-${index}-test`}
            className={`tab-item ${selectedTabIndex === index ? 'selected' : ''}`}
            ref={tab.value === preselected ? selectedRef : null}
            onClick={e => {
              if (!disabled) {
                handleTabClick(e)
                setSelectedTabIndex(index)
                onChangeTab(tab.value)
              }
            }}>
            <p data-test={`tab-item-label-${tab.label}-test`}>{tab.label}</p>
          </div>
        )
      })}
      <div
        data-test={`animated-background-test`}
        className='animated-background'
        style={{
          width: `${backgroundWidth}px`,
          left: `${backgroundLeftSide}px`
        }}
      />
    </div>
  )
}

export default CoreTabs
