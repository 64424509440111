import { useHistory } from 'react-router-dom'

import { useAppDispatch } from '@hooks'
import ArrowIcon from '@images/icons/red-arrow-up.svg?react'
import ExchangeIcon from '@images/icons/red-exchange.svg?react'
import PlusIcon from '@images/icons/white-plus.svg?react'

import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  setStep,
  useAppSelector,
  useMyPermissionsQry
} from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import useHidePaymentButtons from '../../hooks/useHidePaymentButtons'
import CoreButton from '../CoreButton'
import './paymentButtons.scss'

export function PaymentButtons() {
  const dispatch = useAppDispatch()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const history = useHistory()
  const visible = useHidePaymentButtons()
  const { hasAnyWritePermission } = useMyPermissionsQry()

  const handleDepositClick = () => history.push(paths.banking.deposit())

  const handleSendMoneyClick = () => {
    dispatch(setStep(1))
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => history.push(paths.banking.exchange())

  return visible ? (
    <div className='payment-buttons'>
      {isOwnerOrAdministrator(customerRole) && (
        <CoreButton
          LeftIcon={<PlusIcon />}
          data-test='payment-deposit-button'
          size='normal'
          title={tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
          variation='primary'
          onClick={handleDepositClick}
        />
      )}
      {hasAnyWritePermission && (
        <CoreButton
          LeftIcon={<ArrowIcon />}
          data-test='payment-send-button'
          size='normal'
          title={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
          variation='tertiary'
          onClick={handleSendMoneyClick}
        />
      )}
      {isOwnerOrAdministrator(customerRole) && (
        <CoreButton
          LeftIcon={<ExchangeIcon />}
          data-test='payment-exchange-button'
          size='normal'
          variation='tertiary'
          title={tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
          onClick={handleExchangeClick}
        />
      )}
    </div>
  ) : null
}
