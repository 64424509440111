import { useDispatch } from 'react-redux'

import { AppDispatch } from '../store'

export * from './useHideHeaders'
export * from './useDeposit'
export * from './useDownloadFile'
export * from './useOnboardingProgress'
export * from './useInfiniteScroll'
export * from './useMatchMedia'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
