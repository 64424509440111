import { useContext, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'
import CoreSelect from '@components/CoreSelect'

import { useAccountCreateQry } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import './styled/addAccountModal.scss'

interface AddAccountModalProps {
  setCreatedAccount?: (id: number) => void
  currencyOptions?: string[]
}

export const AddAccountModal = ({ setCreatedAccount, currencyOptions }: AddAccountModalProps) => {
  const { modalHide } = useContext(GlobalContext)
  const [filteredCurrencies, setFilteredCurrencies] = useState([])

  const { currencies, formValues, createAccountError, createAccountFetching, handleSubmit } = useAccountCreateQry({
    onSuccess: (_, id) => {
      setCreatedAccount && setCreatedAccount(id)
      toast.success(
        <div className='content'>
          <span className='toast-title'>
            {tr('FRONTEND.ACCOUNTS.ADD_ACCOUNT.SUCCESS_TOAST_TITLE', 'Account successfully added')}
          </span>
          <span className='toast-sub-title'>
            {tr('FRONTEND.ACCOUNTS.ADD_ACCOUNT.SUCCESS_TOAST_SUBTITLE', 'You can now manage your account')}
          </span>
        </div>,
        { duration: 3000, position: 'top-center' }
      )
      modalHide()
    }
  })

  useMemo(() => {
    if (isValidArray(currencies)) {
      if (isValidArray(currencyOptions)) {
        return setFilteredCurrencies(currencies.filter(currency => !!currencyOptions.find(el => el === currency.value)))
      }
      return setFilteredCurrencies(currencies)
    }
  }, [currencyOptions])

  useMemo(() => {
    if (isValidArray(filteredCurrencies) && filteredCurrencies.length == 1) {
      formValues.setValue('currency', filteredCurrencies[0].value)
    }
  }, [filteredCurrencies])

  return (
    <form
      className='add-account-form-wrapper'
      onSubmit={ev => {
        ev.preventDefault()
        handleSubmit()
      }}
      noValidate>
      <div className='add-account-form-inputs'>
        <CoreSelect
          type='currency'
          label={tr('FRONTEND.ACCOUNTS.CREATE.CURRENCY_LABEL', 'Currency')}
          options={filteredCurrencies}
          {...formValues.registerInput('currency')}
        />
        <CoreInput
          label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account name')}
          {...formValues.registerInput('name')}
          clearable
          onChange={(_, value) => formValues.setValue('name', value)}
        />
      </div>

      <ErrorDisplay error={createAccountError} />

      <div className='add-account-form-actions'>
        <CoreButton
          fullWidth
          variation='primary'
          title={tr('FRONTEND.ACCOUNTS.CREATE.SUBMIT_BUTTON', 'Add')}
          size='large'
          isLoading={createAccountFetching}
          disabled={createAccountFetching || !formValues.isValid()}
        />
      </div>
    </form>
  )
}
