import React from 'react'

import AmericanExpressIcon from '@images/icons/cardTypes/amex.svg?react'
import DefaultCardIcon from '@images/icons/cardTypes/card-icon.svg?react'
import DinersIcon from '@images/icons/cardTypes/diners.svg?react'
import DiscoverIcon from '@images/icons/cardTypes/discover.svg?react'
import JCBIcon from '@images/icons/cardTypes/jcb.svg?react'
import MastercardIcon from '@images/icons/cardTypes/mastercard.svg?react'
import VisaIcon from '@images/icons/cardTypes/visa.svg?react'

import { extractCurrencyCode, formatMoney, getCurrencyDecimals, isValidArray, tr } from 'mmfintech-commons'
import { AccountBalanceOut, MerchantActivityOut } from 'mmfintech-commons-types'

export enum adsPagePaths {
  ONE_APP = '/one-app',
  CARD_RIGHT_AWAY = '/card-right-away'
}

const ADS_PAGE_PATHS = ['/one-app', '/card-right-away']

export const calculateExchangeRate = (
  amount: number,
  fromAcc: AccountBalanceOut,
  toAcc: AccountBalanceOut,
  exchangeRate: number,
  isReverse = false
) => {
  const toCurrency = extractCurrencyCode(toAcc)
  const fromCurrency = extractCurrencyCode(fromAcc)

  if (toCurrency === fromCurrency) {
    return amount.toString()
  }

  if (!!amount && !!exchangeRate) {
    return isReverse ? (amount / exchangeRate).toString() : (amount * exchangeRate).toString()
  }

  return 0
}

export const detectCardType = (number: number): string => {
  var re = {
    electron: /^(4026|417500|4508|4844|4913|4917)\d{10}$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4\d{12}(\d{3})?$/,
    mastercard: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
    amex: /^3[47][0-9]{5,}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/
  }

  for (let key in re) {
    if (re[key].test(number)) {
      return key
    }
  }

  return 'other'
}

export const CardIssuerIcon = (number: number): React.FunctionComponent => {
  const cardType = detectCardType(number)

  const cardIcons = {
    visa: VisaIcon,
    amex: AmericanExpressIcon,
    diners: DinersIcon,
    mastercard: MastercardIcon,
    discover: DiscoverIcon,
    jcb: JCBIcon
  }

  if (cardIcons[cardType]) return cardIcons[cardType]

  return DefaultCardIcon
}

function titleCase(str: string): string {
  let splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export const fillActivityFields = (activity: MerchantActivityOut, translatedActivity: string): string => {
  let result = translatedActivity

  if (isValidArray(activity?.activityFields) && translatedActivity.includes('{{')) {
    activity.activityFields.forEach(activityField => {
      let replaceValue = activityField.value
      if (replaceValue.includes('METADATA.') || replaceValue.includes('MERCHANTS.')) {
        replaceValue = tr(activityField.value, titleCase(activityField.value.split('.').pop()))
      }
      if (activityField.key.includes('AMOUNT')) {
        const [value, currency] = activityField.value.split(';')
        replaceValue = formatMoney(value, currency, getCurrencyDecimals(currency))
      }
      result = result.replace(`{{${activityField.key}}}`, replaceValue)
    })
  }
  return result
}

export const cutCryptoAddress = (address: string, firstChars: number = 6, lastChars: number = 4): string => {
  if (!address || address.length <= firstChars + lastChars) {
    return address
  }

  const start = address.slice(0, firstChars)
  const end = address.slice(-lastChars)

  return `${start}...${end}`
}

export const isAdsPage = ({ path }) => {
  return ADS_PAGE_PATHS.includes(path)
}
