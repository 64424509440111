import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import { activityPalette, extractCategoryFromActivity } from '@components/ActivitiesMenu'
import NoActivitiesIcon from '@images/icons/no-activity-icon.svg?react'

import {
  paths,
  transformMerchantActivity,
  useActivitiesQry,
  useLazyGetMerchantActivitiesQuery
} from 'mmfintech-backend-api'
import { isValidArray, tr } from 'mmfintech-commons'
import { MerchantActivityOut } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { fillActivityFields } from '../../../utils/helpers'
import './styled/dashboardRecentActivity.scss'

const ActivityComponent = ({ activity }: { activity: MerchantActivityOut }) => {
  const { handleActivityClick } = useActivitiesQry({
    securityPath: paths.settings()
  })
  const category = extractCategoryFromActivity(activity)
  const { icon } = activityPalette[category]
  const { text } = activity
  const data = transformMerchantActivity(activity)

  return (
    <div
      className='activity-container'
      onClick={() => {
        handleActivityClick(data)
      }}>
      <div data-test='dashboard-recent-activities-icon'>{icon}</div>
      <div className='activity-wrapper'>
        <div data-test='dashboard-recent-activities-category' className='activity-title'>
          {tr(`FRONTEND.ACTIVITY.TYPE.${activity.type}`, category.replace(/_/g, ' '))}
        </div>
        <div data-test='dashboard-recent-activities-content' className='activity-content'>
          {fillActivityFields(activity, tr(`FRONTEND.ACTIVITY.${activity.type}`, text.replace(/<.*?>/g, '')))}
        </div>
      </div>
    </div>
  )
}

const DashboardRecentActivity = () => {
  const [fetchActivities, { lastActivities, lastActivitiesFetching, lastActivitiesError }] =
    useLazyGetMerchantActivitiesQuery({
      selectFromResult: ({ data, error, isFetching }) => ({
        lastActivities: isValidArray(data?.content) ? data.content : [],
        lastActivitiesError: error,
        lastActivitiesFetching: isFetching
      })
    })

  useEffect(() => {
    fetchActivities({
      page: 0,
      size: 4
    })
  }, [])

  return (
    <div className='dashboard-recent-activity-container'>
      <div className='recent-activity-header'>
        <span data-test='dashboard-recent-activities-recent-activity-label'>
          {tr('FRONTEND.DASHBOARD.RECENT_ACTIVITY.TITLE', 'Recent activity')}
        </span>
      </div>
      <div className='recent-activity-content'>
        {lastActivitiesFetching ? (
          <Skeleton count={4} height={60} style={{ borderRadius: '8px' }} />
        ) : isValidArray(lastActivities) ? (
          lastActivities.map((activity, index) => <ActivityComponent key={index} activity={activity} />)
        ) : lastActivitiesError ? (
          <ErrorDisplay error={lastActivitiesError} />
        ) : (
          <div className='no-activities'>
            <NoActivitiesIcon />
            {tr('FRONTEND.DASHBOARD.RECENT_ACTIVITY.NO_RECENT_ACTIVITY', "You don't have any activities")}
          </div>
        )}
      </div>
    </div>
  )
}

export default DashboardRecentActivity
