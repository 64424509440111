import LegalIcon from '@images/icons/legal-requirements-icon.svg?react'
import parse from 'html-react-parser'

import { tr } from 'mmfintech-commons'
import { LegalRequirementResponse, LegalRequirementType } from 'mmfintech-commons-types'

import './LegalRequirements.scss'

const links = {
  TERMS_OF_USE: 'https://www.jeton.com/terms-and-conditions'
}

const supportLink = 'https://jetonhelp.zendesk.com/hc/en-us/articles/4979871935634--How-to-contact-us'

interface LegalRequirementsModal {
  requirements: LegalRequirementResponse[]
}

export const LegalRequirements = ({ requirements }: LegalRequirementsModal) => {
  //todo not localized

  const prepareLink = (type: LegalRequirementType) =>
    `<a href="${links[type]}" target="_blank" rel='noreferrer'>${tr(
      `MERCHANTS.LEGAL_REQUIREMENTS.${type}`,
      type.replace(/_/g, ' ').toLowerCase()
    )}</a>`

  const contactLink = () => {
    return `<a href="${supportLink}" target="_blank" rel='noreferrer'>${tr(
      'MERCHANTS.LEGAL_REQUIREMENTS.CONTACT',
      'Contact Support'
    )}</a>`
  }

  const prepareLinks = () => {
    if (requirements.length > 1) {
      const list = requirements.map(entry => prepareLink(entry.type))
      const last = list.pop()
      return `${list.join(', ')} and ${last}`
    }

    return prepareLink(requirements[0].type)
  }

  return (
    <div className='legal-requirements-wrapper'>
      <div className='icon'>
        <LegalIcon />
      </div>
      <h3>{tr('PORTAL.LEGAL.NOTICE', 'Important Update to Your Jeton Account')}</h3>

      <div className='content'>
        <p>
          {tr(
            'PORTAL.LEGAL.OWNER_LINE1',
            'We’re making changes to improve our services and comply with regulations. Your Jeton account will be moved to our Cyprus-based entity, regulated under Cyprus law.'
          )}
        </p>
        <p>
          {parse(
            tr(
              'PORTAL.LEGAL.OWNER_LINE2',
              `What This Means for You: No Service Changes: Your account and services will remain the same. You can review the updated {{LINKS}}`
            ).replace('{{LINKS}}', prepareLinks())
          )}
        </p>
        <p>
          {tr(
            'PORTAL.LEGAL.OWNER_LINE3',
            'Action Required: To continue using your account, you must accept the updated terms. By clicking "Accept," you agree to this migration and the new terms.'
          )}
        </p>
        <p>
          {parse(
            tr(
              'PORTAL.LEGAL.OWNER_LINE4',
              'If you do not wish to agree with the updated terms and be migrated to our Cyprus-based entity, you must contact our customer support team at {{LINK}} within 2 months of the date of this notification. If we do not hear from you within this period, it will be deemed that you accept the updated terms, and the migration will proceed accordingly.'
            ).replace('{{LINK}}', contactLink())
          )}
        </p>
        <p>
          {parse(
            tr('PORTAL.LEGAL.OWNER_LINE5', `If you have any questions, please contact us at {{LINK}}.`).replace(
              '{{LINK}}',
              contactLink()
            )
          )}
        </p>
      </div>
    </div>
  )
}
